<template>
  <div>
    <v-card class="card-shadow border-radius-xl px-4 py-4">
      <v-row no-gutters>
        <v-col sm="8">
          <p class="text-sm mb-0 font-weight-bold text-body">
            {{ title }}
          </p>
          <h5 class="text-h4 text-typo font-weight-bolder mb-0">{{ value }}</h5>
        </v-col>
        <v-col sm="4" class="text-end">
          <v-avatar :color="`bg-gradient-${status}`" class="shadow" rounded>
            <img
              v-if="icon"
              :src="require(`@/assets/icons/${icon}`)"
              style="padding: 0.5rem"
              alt="icon"
            />
            <v-icon v-else size="20" class="text-white"> ni ni-badge </v-icon>
          </v-avatar>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "MiniCard",
  props: {
    title: {
      type: String,
      default: "title",
    },
    value: {
      type: [String, Number],
      default: "No value",
    },
    icon: {
      type: String,
      default: "",
    },
    status: {
      type: String,
      default: "success",
    },
  },
};
</script>

<style scoped></style>
