<template>
  <v-card class="card-shadow border-radius-xl px-4 py-4">
    <div class="d-flex justify-space-between">
      <div>
        <h4>Usage temps réel des parkings</h4>
      </div>
    </div>
    <!-- ACCORDEON PARKIN TABLES -->
    <div class="mt-12">
      <v-expansion-panels multiple>
        <v-expansion-panel v-for="(parking, idx) in parkingList" :key="idx">
          <v-expansion-panel-header>
            <div
              class=""
              style="
                height: 2rem;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding-right: 2rem;
              "
            >
              <div>
                <h4>{{ parking.name }}</h4>
              </div>
              <div class="top-expansion">
                <div>{{ parking.realtime.occupancy }}%</div>
                <div style="min-width: 250px">
                  <v-progress-linear
                    class="progress-shadow mx-auto"
                    background-color="#e9ecef"
                    color="blue"
                    rounded
                    :value="parking.realtime.occupancy"
                  ></v-progress-linear>
                </div>
              </div>

              <div
                class="m-0 p-0 text-blue"
                style="text-decoration: underline"
                @click.stop="goToParkingDetails(parking.id)"
              >
                Gérer le parking
              </div>
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Places occupées</th>
                    <th class="text-left">Places Disponibles</th>
                    <th class="text-left">Places Réservées</th>
                    <th class="text-left">Capacité</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      {{ parking.realtime.Voiture.occupied }}
                    </td>
                    <td>{{ parking.realtime.Voiture.available }}</td>
                    <td>{{ parking.realtime.Voiture.reserved }}</td>
                    <td>{{ parking.realtime.Voiture.capacity }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </v-card>
</template>

<script>
import { usageRealTime } from "../../dataset/usageRealTime";

export default {
  name: "UsageTempsReel",

  props: {
    parkingList: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      realTimeParking: [
        {
          id: "aze-123-RTY",
          parkingName: "Parking Principal",
          dispo: 22,
          capacity: 40,
        },
        {
          id: "aze-124-RTY",
          parkingName: "Parking Secondaire",
          dispo: 10,
          capacity: 30,
        },
      ],
    };
  },

  created() {
    this.realTimeParking = usageRealTime;
  },

  methods: {
    goToParkingDetails(parkingId) {
      this.$router.push(`dashboard/${parkingId}`);
    },

    /**
     * GET PARKING DATA
     * -------------------------------------------------------------------------
     * helper to compute generic data for a parking statistics
     * -------------------------------------------------------------------------
     * @param {*} parking
     * @returns {Object} {capacity:number, occuped:number, percent:number}
     */
    getParkingData(parking) {
      const types = ["car", "motorbike", "bike", "other"];
      let capacity = 0;
      let occuped = 0;
      types.forEach((elm) => {
        capacity += parking[elm]["capacity"];
        occuped += parking[elm]["occuped"];
      });
      let percent = (occuped * 100) / (capacity === 0 ? 1 : capacity);
      console.log("getParkingData: ", capacity, occuped, percent);
      return { capacity, occuped, percent };
    },
  },
};
</script>

<style scoped>
.top-expansion {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 6px;
  margin-right: 2rem;
}
</style>
