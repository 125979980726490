<template>
  <div>
    <v-card class="card-shadow border-radius-xl px-4 py-4 w-100">
      <h4>Réservations</h4>
      <v-simple-table class="mt-12">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">PARKING</th>
              <th class="text-left">PLACES RESERVABLES <br />AUJOURD'HUI</th>
              <th class="text-left">PLACES RESERVABLES <br />DEMAIN</th>
              <th class="text-left"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(reservation, idx) in reservations" :key="idx">
              <td>{{ reservation.name }}</td>
              <td>{{ reservation.reservations.reservableSpotsToday }}</td>
              <td>{{ reservation.reservations.reservableSpotsTomorrow }}</td>
              <td
                class="m-0 p-0 text-blue"
                style="text-decoration: underline; cursor: pointer"
                @click.stop="goToReservationDetails(reservation.id)"
              >
                Gérer les réservations
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "ReservationTable",

  props: {
    reservations: {
      type: Array,
      requires: true,
    },
  },
  methods: {
    goToReservationDetails(id) {
      this.$router.push(`/reservations/${id}`);
    },
  },
};
</script>

<style scoped></style>
