export const usageRealTime = [
  {
    id: "123",
    name: "parking1",
    capacity: 50,
    car: {
      capacity: 25,
      occuped: 10,
    },
    motorbike: {
      capacity: 8,
      occuped: 4,
    },
    bike: {
      capacity: 12,
      occuped: 7,
    },
    other: {
      capacity: 5,
      occuped: 1,
    },
  },
  {
    id: "456",
    name: "parking2",
    capacity: 100,
    car: {
      capacity: 50,
      occuped: 20,
    },
    motorbike: {
      capacity: 16,
      occuped: 4,
    },
    bike: {
      capacity: 24,
      occuped: 7,
    },
    other: {
      capacity: 10,
      occuped: 1,
    },
  },
];
