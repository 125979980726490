<template>
  <div class="container">
    <v-row>
      <v-col cols="3">
        <MiniCard
          title="Garage à vélo"
          value="Fonctionnel"
          icon="bicycleGarage.svg"
        ></MiniCard>
      </v-col>
      <v-col cols="3">
        <MiniCard
          title="Ouvrant principal"
          value="Fonctionnel"
          icon="mainOpenning.svg"
        ></MiniCard>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <GestionCourante></GestionCourante>
      </v-col>
    </v-row>
    <v-row>
      <div v-if="pendingRequest" class="grid-center-h-v">
        <v-progress-circular
          :size="32"
          :width="2"
          indeterminate
          color="primary"
        ></v-progress-circular>
      </div>
      <v-col v-else cols="12">
        <UsageTempsReel :parkingList="parkingList"></UsageTempsReel>
      </v-col>
    </v-row>
    <v-row>
      <div v-if="pendingRequest" class="grid-center-h-v">
        <v-progress-circular
          :size="32"
          :width="2"
          indeterminate
          color="primary"
        ></v-progress-circular>
      </div>
      <v-col v-else cols="12">
        <ReservationTable :reservations="parkingList"></ReservationTable>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import axios from "axios";
import MiniCard from "../../components/Dynapark/MiniCard.vue";
import GestionCourante from "../../components/Dynapark/GestionCourante.vue";
import UsageTempsReel from "../../components/Dynapark/UsageTempsReel.vue";
import ReservationTable from "../../components/Dynapark/ReservationTable.vue";

export default {
  name: "TableauDeBord",
  components: {
    MiniCard,
    UsageTempsReel,
    GestionCourante,
    ReservationTable,
  },

  data() {
    return {
      pendingRequest: false,
      parkingList: [],
    };
  },

  beforeMount() {
    // -- Fetch dataset for reservationTable
    this.pendingRequest = true;
    axios
      .get(`${this.$store.getters.apiurl}/parkings`)
      .then((res) => {
        this.parkingList = res.data;
        this.pendingRequest = false;
      })
      .catch((err) => {
        this.pendingRequest = false;
        console.error("Fetch parkings ERROR: ", err);
      });
  },

  methods: {
    isParkingGetBarrier(parkings) {
      return parkings.some(
        (parking) => parking.barriers && parking.barriers.length > 0
      );
    },
  },
};
</script>

<style scoped></style>
